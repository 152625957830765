import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import activationImg from 'assets/images/activation_img.svg';

import Layout from 'Layout/Layout';

import { withLocale } from '@dietlabs/components';

class ConfirmPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                <section>
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="img-fluid"
                                    src={activationImg}
                                    alt=""
                                />
                                <h1>{this.props.t('auth/confirm/header')}</h1>
                                <h2>
                                    {this.props.t('auth/confirm/subheader')}
                                </h2>
                                <p>{this.props.t('auth/confirm/content')}</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(ConfirmPage);
