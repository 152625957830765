import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Price from 'view/Price/Price';

export class ShoppingCartIndexSummaryComponent extends React.Component {
    render() {
        return (
            <section className="cart-summary with-border">
                <Container>
                    <Row data-test="total-price" noGutters>
                        <Col xs="8" md="10" className="text-md-right">
                            <strong>
                                {this.props.t('cart/summary/total')}
                            </strong>
                        </Col>
                        <Col xs="4" md="2" className="price">
                            <strong>{this.props.totalPrice.toString()}</strong>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

ShoppingCartIndexSummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    totalPrice: PropTypes.instanceOf(Price).isRequired,
};

export default withLocale(ShoppingCartIndexSummaryComponent);
