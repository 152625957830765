import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import { Row, Col, Button, Container } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATHS } from 'config/paths';
import scrollToElement from 'components/Helpers/scrollToElement';
import ShoppingCartIndexProductContainer from 'view/ShoppingCart/Index/Product/ShoppingCartIndexProductContainer';
import ShoppingCartIndexDeliveryContainer from 'view/ShoppingCart/Index/Delivery/ShoppingCartIndexDeliveryContainer';
import ShoppingCartIndexSummaryContainer from 'view/ShoppingCart/Index/Summary/ShoppingCartIndexSummaryContainer';
import { SHOPPING_CART_INDEX_SAVE_SUCCESS_RECEIVED_RESPONSE } from 'view/ShoppingCart/Index/Save/ShoppingCartIndexSaveActionType';
import Layout from 'Layout/Layout';
import ShoppingCartIndexDeliveryUserDataContainer from 'view/ShoppingCart/Index/Delivery/ShoppingCartIndexDeliveryUserDataContainer';
import ShoppingCartIndexPlaceholder from './ShoppingCartIndexPlaceholder';

export class ShoppingCartIndexComponent extends React.Component {
    constructor(props) {
        super(props);

        this.scrollAnchorRef = React.createRef();
        this.scrollAnchorRefLogin = React.createRef();

        this.state = {
            initialized: false,
            shouldRedirectToUpSell: false,
            shouldRedirectToConfirm: false,
        };
    }

    componentDidMount() {
        if (this.props.areThereSomeProductsInCart) {
            this.props.load().then(() => {
                this.props
                    .saveCartProducts()
                    .then(() => this.setState({ initialized: true }));
            });
        } else {
            this.setState({ initialized: true });
        }
    }

    async saveCart() {
        const result = await this.props.saveCart();
        try {
            if (
                result.type ===
                SHOPPING_CART_INDEX_SAVE_SUCCESS_RECEIVED_RESPONSE
            ) {
                if (result.upSellProducts.length) {
                    this.setState({ shouldRedirectToUpSell: true });
                } else {
                    this.setState({ shouldRedirectToConfirm: true });
                }
            } else if (result.details[0].fieldName === 'acceptConditions') {
                scrollToElement(this.scrollAnchorRefLogin.current);
            } else {
                scrollToElement(this.scrollAnchorRef.current);
            }
        } catch (exception) {
            // nothing to do here
        }
    }

    render() {
        let result = this.constructor.renderPlaceholder();
        if (this.state.initialized) {
            if (!this.props.areThereSomeProductsInCart) {
                window.location = PATHS.EXTERNAL.HOME;
                result = null;
            } else if (this.state.shouldRedirectToUpSell) {
                result = <Redirect push to={PATHS.CART.UPSELL} />;
            } else if (this.state.shouldRedirectToConfirm) {
                const pathToCartConfirm =
                    PATHS.CART.CONFIRM.split(':')[0] + this.props.token;
                result = <Redirect push to={pathToCartConfirm} />;
            } else {
                result = this.renderInitializedComponent();
            }
        }
        return result;
    }

    renderInitializedComponent() {
        return (
            <Layout page="cart" title={this.props.t('cart/title')}>
                <Container>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <ShoppingCartIndexProductContainer />
                            <ShoppingCartIndexSummaryContainer />
                            <ShoppingCartIndexDeliveryContainer />
                            {localStorage.getItem('token') ? (
                                ''
                            ) : (
                                <div ref={this.scrollAnchorRefLogin}>
                                    <ShoppingCartIndexDeliveryUserDataContainer />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>

                <section className="text-center pay pb-5">
                    <Container>
                        <Row>
                            <Col md={{ size: 4, offset: 4 }}>
                                <Button
                                    data-test="save-cart-button"
                                    onClick={() => this.saveCart()}
                                    color="primary"
                                    className="w-100"
                                >
                                    {this.props.t('cart/next')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }

    static renderPlaceholder() {
        return (
            <ShoppingCartIndexPlaceholder data-test="shopping-cart-index-placeholder" />
        );
    }
}

ShoppingCartIndexComponent.propTypes = {
    t: PropTypes.func.isRequired,
    areThereSomeProductsInCart: PropTypes.bool.isRequired,
    load: PropTypes.func.isRequired,
    saveCart: PropTypes.func.isRequired,
    saveCartProducts: PropTypes.func.isRequired,
    token: PropTypes.string,
};

ShoppingCartIndexComponent.defaultProps = {
    token: undefined,
};

export default withRouter(withLocale(ShoppingCartIndexComponent));
