import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { PATHS } from 'config/paths';
import logo from 'assets/icons/logo.svg';
import ShoppingCartUserData from 'view/ShoppingCart/UserData/ShoppingCartUserData';
import removeAuthCredentials from '@dietlabs/components/src/Auth/removeAuthCredentials';
import { withLocale } from '@dietlabs/components';
import { withApollo } from 'react-apollo';

class MenuLayout extends Component {
    static propTypes = {
        title: PropTypes.string,
        t: PropTypes.func.isRequired,
        client: PropTypes.shape({
            clearStore: PropTypes.func.isRequired,
        }).isRequired,
        setUserData: PropTypes.func.isRequired,
    };

    static defaultProps = {
        title: undefined,
    };

    handleLogout = () => {
        const newUserData = new ShoppingCartUserData();
        this.props.setUserData(newUserData);

        // clear user data
        this.props.client.clearStore().then(() => {
            global.localStorage.removeItem('token');
            global.localStorage.removeItem('sex');
            global.localStorage.removeItem('apollo-cache-persist');
            removeAuthCredentials();
            window.location.reload();
        });
    };

    render() {
        return (
            <section className="main-menu">
                <Container>
                    <Row>
                        <Col xs="6" md="4" className="text-md-left">
                            <a
                                className="navbar-brand"
                                href={PATHS.EXTERNAL.HOME}
                            >
                                <img src={logo} alt="MAXXnation" />
                            </a>
                        </Col>
                        <Col md="4" className="text-center d-none d-md-block">
                            <h2 className="mt-3">
                                {this.props.title && this.props.title}
                            </h2>
                        </Col>
                        <Col
                            xs="6"
                            md="4"
                            className="text-right d-flex align-items-center justify-content-end"
                        >
                            {global.localStorage.getItem('token') !== null && (
                                <Button
                                    color="link"
                                    onClick={this.handleLogout}
                                >
                                    {this.props.t('logout')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withApollo(withLocale(MenuLayout));
