export const PATHS = {
    EXTERNAL: {
        HOME: 'https://maxxnation.pl/',
    },
    DEFAULT_PAGE_AFTER_LOGGED_IN: '/achievements',
    ERROR_404: '/error/404',
    ERROR_APPLICATION: '/error/application',
    ERROR_NETWORK: '/error/network',
    CONTACT: '/contact',
    AUTH: {
        CONFIRMED: '/auth/confirm',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        LOGIN_BY_TOKEN: '/auth/login/by-token/:token([a-zA-Z0-9]+)',
        REMIND_PASSWORD: '/auth/remind-password',
        RESET_PASSWORD: '/auth/reset-password/:token([a-zA-Z0-9]+)',
        RESET_PASSWORD_RESULT:
            '/auth/reset-password-result/:result(success|error)',
        SET_PASSWORD: '/auth/set-password',
        FACEBOOK_LOGIN: '/auth/login/via-facebook',
        GOOGLE_LOGIN: '/auth/login/via-google',
        APPLE_LOGIN: '/auth/login/via-apple',
        APPLE_REGISTER: '/auth/register/via-apple',
        FACEBOOK_REGISTER: '/auth/register/via-facebook',
        GOOGLE_REGISTER: '/auth/register/via-google',
        FACEBOOK_ACTIVATE: '/auth/activate/via-facebook',
        GOOGLE_ACTIVATE: '/auth/activate/via-google',
        APPLE_ACTIVATE: '/auth/activate/via-apple',
        ACTIVATE: '/auth/activate/:token([a-zA-Z0-9]+)',
    },
    BUY: '/buy',
    PROMO_CODE: {
        EN: '/coupon',
        PL: '/kupon',
    },
    COUPON: {
        INDEX: '/aktywacja',
        ACTIVATE: '/coupon/access-code',
    },
    BLOCK_COOKIES: '/block-cookies',
    NEWSLETTER: {
        UNSUBSCRIBE: '/unsubscribe',
    },
    CART: {
        INDEX: '/cart',
        ADD: '/cart/add/:productId([0-9]+)/:source?',
        UPSELL: '/cart/upsell',
        CONFIRM: '/cart/confirm/:token',
        THANK_YOU: '/cart/thank-you/:token',
        BANK_TRANSFER: '/cart/bank-transfer/:token',
    },
    REDIRECT_HELPER: '/redirect-to',
};
