import React from 'react';
import { sha256 } from 'js-sha256';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';

import badgeAppStore from 'assets/icons/badge-appstore2.svg';
import badgeGooglePlay from 'assets/icons/badge-googleplay2.svg';

import { PATHS } from 'config/paths';
import thankyou from 'assets/images/thankyou.jpeg';

export class ShoppingCartThankYouComponent extends React.Component {
    render() {
        return (
            <Layout page="thnk-you">
                {/* div for marketing analitycs */}
                <div id="conversion_hash" className="d-none">
                    {sha256(this.props.userEmail)}
                </div>
                <section>
                    <Container>
                        <Row>
                            <Col
                                md={{ size: 4, offset: 4 }}
                                className="text-center"
                            >
                                <img
                                    srcSet={`${thankyou} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="mb-4"
                                />

                                <h1 className="heading-2">
                                    {this.props.t('thank-you/title')}
                                </h1>
                                <p>
                                    {this.props.t('thank-you/content1')}
                                    <br />
                                    {this.props.t('thank-you/content2')}
                                </p>

                                <div className="grey-box">
                                    <p className="mb-0">
                                        <strong>
                                            {this.props.t(
                                                'thank-you/no-account'
                                            )}
                                        </strong>
                                    </p>
                                </div>

                                <hr />

                                <p>
                                    {this.props.t('thank-you/content3')}
                                    <br />
                                    {this.props.t('thank-you/content4')}
                                </p>

                                <a
                                    href={APP_STORE_URL}
                                    className="mx-2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={badgeAppStore} alt="App Store" />
                                </a>
                                <a
                                    href={GOOGLE_PLAY_URL}
                                    className="mx-2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={badgeGooglePlay}
                                        alt="Google Play"
                                    />
                                </a>

                                <a
                                    href={PATHS.EXTERNAL.HOME}
                                    className="btn btn-primary btn-outline mt-4 w-100"
                                >
                                    {this.props.t('thank-you/go-to-home')}
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

ShoppingCartThankYouComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
};

export default withLocale(ShoppingCartThankYouComponent);
