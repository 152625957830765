import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withOnCriticalError from 'view/ErrorBoundary/withOnCriticalError';
import { PATHS } from 'config/paths';
import ShoppingCartConfirmPaymentOnlineContainer from 'view/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentOnlineContainer';
import Payment from 'view/Payment/Payment';
import ShoppingCartOrderProductsSummaryComponent from 'components/ShoppingCart/Order/ShoppingCartOrderProductsSummaryComponent';
import ShoppingCartConfirmPlaceholder from 'components/ShoppingCart/Confirm/ShoppingCartConfirmPlaceholder';
import Layout from 'Layout/Layout';
import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import store from 'store';
import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';
import { withApollo } from 'react-apollo';

export class ShoppingCartConfirmComponent extends React.Component {
    state = {
        initialized: false,
    };

    async componentDidMount() {
        try {
            if (!this.props.payment.isInit() && !this.props.payment.isCod()) {
                const clonedPayment = await this.props.clonePayment(
                    this.props.payment.token
                );
                const redirectTo =
                    PATHS.CART.CONFIRM.split(':')[0] + clonedPayment.token;

                this.setState({
                    initialized: true,
                    redirectTo,
                });
            } else {
                this.setState({ initialized: true });
            }
        } catch (error) {
            this.props.onCriticalError(error);
        }
    }

    render() {
        if (this.state.initialized) {
            if (this.state.redirectTo) {
                if (this.state.redirectTo !== global.location.pathname) {
                    return <Redirect to={this.state.redirectTo} />;
                }
                return '';
            }

            const { payment } = this.props;

            const backToCart = payment.isInit() ? (
                <section className="pt-md-2 pb-md-5 pb-4">
                    <Button
                        data-test="back-to-cart"
                        tag={Link}
                        to={PATHS.CART.INDEX}
                        color="link"
                    >
                        <span className="SVGInline mr-2">
                            <LeftArrow />
                        </span>
                        {this.props.t('cart/back-to-cart')}
                    </Button>
                </section>
            ) : (
                ''
            );

            let userEmail;
            try {
                const userData = this.props.client.readQuery({
                    query: UserDataQuery,
                });
                userEmail = userData.me.email;
            } catch {
                userEmail = store.getState().shoppingCart.userData.email;
            }
            return (
                <Layout page="cart" title={this.props.t('cart/order-confirm')}>
                    <Container className="mt-4">
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                {userEmail && (
                                    <section className="with-border">
                                        <h2>
                                            {this.props.t('cart/user-data')}
                                        </h2>
                                        <p className="mb-0">
                                            E-mail: {userEmail}
                                        </p>
                                    </section>
                                )}

                                <ShoppingCartOrderProductsSummaryComponent
                                    products={payment.products}
                                    productsPrice={payment.productsPrice}
                                    deliveryOptionPrice={
                                        payment.deliveryOptionPrice
                                    }
                                    totalPrice={payment.totalPrice}
                                />

                                <ShoppingCartConfirmPaymentOnlineContainer
                                    token={payment.token}
                                />

                                {backToCart}
                            </Col>
                        </Row>
                    </Container>
                </Layout>
            );
        }
        return <ShoppingCartConfirmPlaceholder />;
    }
}

ShoppingCartConfirmComponent.propTypes = {
    payment: PropTypes.instanceOf(Payment).isRequired,
    clonePayment: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onCriticalError: PropTypes.func.isRequired,
    client: PropTypes.shape({
        readQuery: PropTypes.func.isRequired,
    }).isRequired,
};

export default withOnCriticalError(
    withApollo(withLocale(ShoppingCartConfirmComponent))
);
