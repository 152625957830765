import { connect } from 'react-redux';
import { shoppingCartSetUserDataAction } from 'view/ShoppingCart/UserData/ShoppingCartUserDataAction';
import Menu from './Menu';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    setUserData: userData => dispatch(shoppingCartSetUserDataAction(userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
