import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';

import { ReactComponent as IconClose } from 'assets/icons/remove-icon.svg';

export class ShoppingCartIndexProductComponent extends React.Component {
    render() {
        return (
            <section className="cart-product pt-4 pb-0">
                <h2>{this.props.t('cart/products')}</h2>
                {this.props.products.map(product => (
                    <Row
                        key={product.id}
                        data-test="product-row"
                        className="product-row align-items-center"
                    >
                        <Col xs="3" className="d-sm-none">
                            <img
                                src={product.thumbnailUrl}
                                className="img-fluid"
                            />
                        </Col>
                        <Col xs="7" sm="10" md="12">
                            <Row className="align-items-center">
                                <Col sm="7">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={product.thumbnailUrl}
                                            className="product-thumbnail d-none d-sm-block mr-4"
                                        />
                                        <div
                                            className="product-name"
                                            dangerouslySetInnerHTML={{
                                                __html: product.name,
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col sm="2" className="d-none d-md-block">
                                    <Button
                                        color="link"
                                        className="p-0"
                                        data-test="button-remove"
                                        onClick={() =>
                                            this.props.remove(product.id)
                                        }
                                    >
                                        <IconClose />
                                    </Button>
                                </Col>
                                <Col xs="12" sm="3" className="text-md-right">
                                    <span className="d-block mt-2 mt-md-0">{`${product.sumPrice}`}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="2" md="0" className="text-right d-md-none">
                            <Button
                                color="link"
                                className="p-0"
                                data-test="button-remove"
                                onClick={() => this.props.remove(product.id)}
                            >
                                <IconClose />
                            </Button>
                        </Col>
                    </Row>
                ))}
            </section>
        );
    }
}

ShoppingCartIndexProductComponent.propTypes = {
    remove: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
};

export default withLocale(ShoppingCartIndexProductComponent);
