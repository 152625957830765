import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import logo from 'assets/icons/logo2.svg';
import badgeAppStore from 'assets/icons/badge-appstore.svg';
import badgeGooglePlay from 'assets/icons/badge-googleplay.svg';
import activerse from 'assets/icons/activerse_logo_horizontal.svg';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import { PATHS } from '../config/paths';

class Footer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        forceReloadLinks: PropTypes.bool,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    state = {
        isOpen: false,
    };

    renderNavLink(href, text) {
        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={href}>
                    {text}
                </a>
            );
        }

        return (
            <Link className="nav-link" to={href}>
                {text}
            </Link>
        );
    }

    handleClick = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        return (
            <footer className="d-print-none">
                <Container>
                    <Row>
                        <Col
                            xs={{ size: 12, order: 1 }}
                            md={{ size: 4, order: 1 }}
                            className="text-center text-md-left"
                        >
                            <img src={logo} className="mb-4" alt="MAXXnation" />
                        </Col>
                        <Col
                            xs={{ size: 6, order: 3 }}
                            md={{ size: 4, order: 2 }}
                            className="text-right text-md-center"
                        >
                            <a
                                href={APP_STORE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mx-md-2 mb-3 store-btn"
                            >
                                <img
                                    src={badgeAppStore}
                                    className="img-fluid"
                                    alt="App Store"
                                />
                            </a>

                            <a
                                href={GOOGLE_PLAY_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mx-md-2 store-btn"
                            >
                                <img
                                    src={badgeGooglePlay}
                                    className="img-fluid"
                                    alt="Google Play"
                                />
                            </a>
                        </Col>
                        <Col
                            xs={{ size: 6, order: 2 }}
                            md={{ size: 4, order: 3 }}
                        >
                            <Nav vertical>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/how-to',
                                        this.props.t('footer/service-access')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/terms-of-service',
                                        this.props.t('footer/tos')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/privacy-policy',
                                        this.props.t('footer/privacy-policy')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATHS.CONTACT,
                                        this.props.t('footer/contact')
                                    )}
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6">
                            <p className="text-center text-md-left small">
                                proudly created by{' '}
                                <a href="https://activerse.app/">
                                    <img
                                        src={activerse}
                                        className="ml-2"
                                        alt="activerse.app"
                                    />
                                </a>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="text-center text-md-right small">
                                Copyright ©{new Date().getFullYear()}{' '}
                                activerse.app
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default withLocale(Footer);
