import packageJson from '../package.json';

export const LANGUAGE = 'pl';

export const SENTRY_DSN = '';

export const DIET_ID_CLASSIC = 3;
export const DIET_ID_VEGE = 4;

export const APP_STORE_URL = 'itms-apps://itunes.apple.com/app/id1546994529';
export const GOOGLE_PLAY_URL =
    'https://play.google.com/store/apps/details?id=com.maxxnation.workout_for_men';

export const FLASH_MESSAGES = {
    AUTH: {
        REMIND_PASSWORD: {
            SUCCESS: 'auth.remindPassword.success',
        },
        RESET_PASSWORD: {
            SUCCESS: 'auth.resetPassword.success',
            FAILED: 'auth.resetPassword.invalidToken',
        },
        SET_INITIAL_PASSWORD: {
            NO_PASSWORD: 'auth.setInitialPassword.noPassword',
            SUCCESS: 'auth.setInitialPassword.success',
        },
    },
    COUPON: {
        SUCCESS: 'coupon.success',
    },
    PROMO_CODE: {
        SUCCESS: 'promoCode.success',
    },
    CART: {
        INVALID_PRODUCT_ID: 'cart.invalidProductId',
    },
};

export const SYSTEM_OF_MEASURES = {
    SI: 'si',
    IMPERIAL: 'imperial',
};

export const APP_TAB_DIET = 'diet';
export const APP_TAB_MEASUREMENTS = 'measure';
export const APP_TAB_SHOPPING_LIST = 'shopping-list';
export const APP_TAB_HYDRATION = 'hydration';
export const APP_TAB_MORE = 'profile';
export const APP_TABS = [
    APP_TAB_DIET,
    APP_TAB_MEASUREMENTS,
    APP_TAB_SHOPPING_LIST,
    APP_TAB_HYDRATION,
    APP_TAB_MORE,
];

export const UNIT_TYPES = {
    BODY_WEIGHT: {
        SI: 'kg',
        IMPERIAL: 'lb',
    },
    LENGTH_BODY_MEASUREMENT: {
        SI: 'cm',
        IMPERIAL: 'in',
    },
    VOLUME: {
        SI: 'ml',
        IMPERIAL: 'oz',
    },
};

export const disableCodForProductsArray = [324, 325, 326];

export const FRISCO_TRACKING_URL = '';

export const IMAGE_SHARING_URL = '';

export const APP_VERSION = packageJson.version;
