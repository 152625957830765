import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Price from 'view/Price/Price';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';

const SHOW_PRODUCTS_MIN_HEIGHT_EM = 50;

export class ShoppingCartOrderProductsSummaryComponent extends React.Component {
    // eslint-disable-next-line class-methods-use-this
    get defaultShowProducts() {
        if (typeof window.matchMedia !== 'function') {
            return false;
        }

        return window.matchMedia(
            `(min-height: ${SHOW_PRODUCTS_MIN_HEIGHT_EM}em)`
        ).matches;
    }

    renderProductList() {
        return this.props.products.map(product => (
            <Row
                key={product.id}
                data-test="product-row"
                className="summary-product"
            >
                <Col xs="3" sm="2" md="1" className="pr-0 d-sm-none">
                    <img src={product.thumbnailUrl} className="img-fluid" />
                </Col>
                <Col xs="9" sm="12">
                    <Row className="align-items-center">
                        <Col sm="8">
                            <div className="d-flex align-items-center">
                                <img
                                    src={product.thumbnailUrl}
                                    className="product-thumbnail d-none d-sm-block mr-4"
                                />
                                <div
                                    className="product-name"
                                    dangerouslySetInnerHTML={{
                                        __html: product.name,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            sm="4"
                            className="text-sm-right"
                            data-test="products-price"
                        >
                            <span className="d-sm-none">Cena: </span>
                            {`${product.sumPrice}`}
                        </Col>
                    </Row>
                </Col>
            </Row>
        ));
    }

    render() {
        return (
            <section className="cart-summary with-border">
                <h2>{this.props.t('cart/order-summary')}</h2>
                {this.renderProductList()}

                <Row data-test="total-price">
                    <Col xs="8" md="10" className="text-md-right">
                        <strong>{this.props.t('cart/summary/total')}</strong>
                    </Col>
                    <Col xs="4" md="2" className="price">
                        <strong>{this.props.totalPrice.toString()}</strong>
                    </Col>
                </Row>
            </section>
        );
    }
}

ShoppingCartOrderProductsSummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartProduct))
        .isRequired,
    totalPrice: PropTypes.instanceOf(Price).isRequired,
};

export default withLocale(ShoppingCartOrderProductsSummaryComponent);
